import LoadingV2 from 'components/2024/Loading';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from 'components/ui/table';
import { opacityAnimate } from 'constants/animate';
import { AnimatePresence, motion } from 'framer-motion';
import { formatDateTime, handleLoadingAndFetchData } from 'helper';
import { UserSubmitView } from 'interface';
import { useEffect, useState } from 'react';
import { getAllSubmitMessage } from 'utils/firebase';

const ViewTab = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [submittedList, setSubmittedList] = useState<UserSubmitView[]>([]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			handleLoadingAndFetchData<UserSubmitView[]>(
				1000,
				getAllSubmitMessage,
				value => {
					setSubmittedList(value);
					setLoading(false);
				}
			);
		})();
	}, []);

	return (
		<div>
			<AnimatePresence>
				{loading && (
					<div className='w-full mx-auto absolute h-[100px] scale-50'>
						<LoadingV2 />
					</div>
				)}
			</AnimatePresence>
			{!loading && (
				<motion.div {...opacityAnimate}>
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead className='w-[20px]'>#</TableHead>
								<TableHead>Code</TableHead>
								<TableHead>Name</TableHead>
								<TableHead className='w-[300px]'>Message</TableHead>
								<TableHead>Submitted At</TableHead>
							</TableRow>
						</TableHeader>

						<TableBody>
							{submittedList.map((item, index) => (
								<TableRow key={item.id}>
									<TableCell>{index + 1}</TableCell>
									<TableCell>{item.id}</TableCell>
									<TableCell>{item.name}</TableCell>
									<TableCell>{item.msg}</TableCell>
									<TableCell>
										{formatDateTime(item.submittedAt * 1000)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</motion.div>
			)}
		</div>
	);
};

export default ViewTab;
