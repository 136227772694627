import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
	findMessageFromFirebase,
	getAllGiftsCodes,
	getOpenTime,
	getServerTime,
} from '../utils/firebase';
import FormInfo from 'components/2024/FormInfo';
import { AppState } from 'interface';
import { useAtom } from 'jotai';
import { appStateAtom, giftMessageAtom } from 'atom';
import { handleLoadingAndFetchData } from 'helper';
import { CURRENT_YEAR } from '../constants';
import { Toaster } from 'react-hot-toast';
import LoadingV2 from 'components/2024/Loading';
import { AnimatePresence } from 'framer-motion';

const GiftDetail2024 = () => {
	// id = giftCode
	const { id } = useParams<{ id: string }>();
	const [appState, setAppState] = useAtom(appStateAtom);
	const [, setGiftMessage] = useAtom(giftMessageAtom);

	const getAppState = async (code: string): Promise<AppState> => {
		const allCodes = await getAllGiftsCodes();
		if (!allCodes) return 'invalid';
		const isExist = allCodes[CURRENT_YEAR]?.find(c => c === code);
		if (!isExist) return 'invalid';

		const info = await findMessageFromFirebase(code);
		const data = info?.data();

		const currentTime = (await getServerTime()).getTime();
		const openTime = await getOpenTime();

		if (currentTime && openTime && currentTime >= openTime) {
			if (data) {
				setGiftMessage(data.msg);
				return 'open';
			}
			return 'valid';
		}

		return data ? 'exist' : 'valid';
	};

	const updateAppState = (state: AppState) => {
		setAppState(state);
	};

	const handleLoadingAndSetState = async (code?: string) => {
		if (!code) return;
		handleLoadingAndFetchData<AppState>(
			2000,
			() => getAppState(code),
			updateAppState
		);
	};

	useEffect(() => {
		handleLoadingAndSetState(id);
	}, [id]);

	return (
		<div className='version-2024'>
			<div className='app-container'>{appState && <FormInfo code={id} />}</div>
			<AnimatePresence>
				{!appState && (
					<div className='w-full h-[200px] fixed top-[100px]'>
						<LoadingV2 />
					</div>
				)}
			</AnimatePresence>
			<Toaster />
		</div>
	);
};

export default GiftDetail2024;
