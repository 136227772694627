export const CURRENT_YEAR = 2024;

export const SUBMIT_DONE_MSG = 'Thanks for your message.\nSee you on December 23rd!';

export const NOT_FOUND_CODE = 'Gift code is invalid. Please try again. ';

export const OPEN_MSG = 'Suỵt!\nNhấn mở để đọc được lời nhắn nhé!';

export const ERROR_NAME_EMPTY = 'Please input your name 🎅';

export const ERROR_COMMON = 'An error occured. Please try again!';

export const QR_URLS = {
  2023: [
    'mgrpp2znp8',
    '1oj1cuw7rf',
    'hiwfvreoif',
    'btonvfucuc',
    'o6p5r6t2zi',
    '2oc2cuwpmg',
    '6r72sr6geq',
    'b1b8bbobzt',
    'k48noabvm4',
    'caw3p2drsz',
    'fm5a5rgzqx',
    'yhlvm9iypc',
    'xrpgy210x9',
    'bekqis7ct7',
    'w638llm39w',
    'cntmqm0csd',
    'gcaxo2w6pi',
    'zn9v5ttume',
    '6vynd42a5w',
    '2tog5gdln9',
    'd56vduqfjl',
    '0ac6elzj3y',
    'g5udx8d1ht',
    'ebq9y5u0af',
    'bfu9crx2hv',
    'e7lfd79ii5',
    'jf6xsvw2rj',
    'kco646grdy',
    'gcr0thmkrb',
    'kcs218ulwz',
    'tv5inep9nw',
    't7pagnndzo',
    'p484wplqla',
    'yiavz3fhf4',
    '4o87w9hfne',
    'm3wcqf5xg4',
    'fftv12eqq6',
    'ghvdwdzhtb',
    'wrps9tlos4',
    'rbutm3jfwe',
    'ww36umt6te',
    '0adkyu1k8s',
    'ffhs6mtcwb',
    'dn1xp9bagm',
    'j1lk11sh9x',
    '4qyd3l35az',
    '5gyrrwgjr0',
    '90aj920ulr',
    'z6s7ijm6hw',
    '6iyjgl3xwq',
  ],
  2024: [
    "03r1r9xua773",
    "2ga3wikc5b20",
    "ft3vhum876x0",
    "da0c6ju4nb8r",
    "phyqgd7jpvd8",
    "zhkgc58x4nxt",
    "n1z6ecru1iyz",
    "kvbm0yc2zr5q",
    "kpktnh3088aw",
    "y9kz9ginzxc0",
    "56rdx01fuwee",
    "p526kkdz8fae",
    "jkzhduuefva6",
    "f214mw6936ka",
    "k5cqjb9n4375",
    "r8xiudg11enm",
    "8h5m11a67222",
    "g483umic26et",
    "fy2qrr14gfzd",
    "ji02d68f15qx",
    "xz1n9wja4wan",
    "18yvvxi9e75k",
    "09cjfqpghi1n",
    "r2e1zw0kpp2v",
    "jb1xikiejfer",
    "7mtfxb9q2x32",
    "5wmecchepmc9",
    "d1piv3ia8w73",
    "nyjcny0tubhp",
    "rnmujutyk757",
    "93bhh63euzz9",
    "wq68iyfw1g4c",
    "hx3hu7xwx6db",
    "g820mvupwjkj",
    "yvephq377m3g",
    "thmqxcq9e64g",
    "044420k3kvce",
    "yyg0vz8whihc",
    "0hereuab3rxa",
    "4yekbq1bmc6j",
    "j4rii9ujmmt5",
    "5qgrx02vcazw",
    "cbbdr93qfkh5",
    "e5318uhx1hyi",
    "8j7b8598kg9g",
    "ve19iw3btgzu",
    "nehh8u3xkn8q",
    "vda6283ip857",
    "zcufj4gzmxdi",
    "agvzdvphcnpz"
  ]
}

export const DEFAULT_MSG = [
  'Merry Christmas and Happy New Year! Executionlab hope you have a wonderful time with your family and friends. May this season bring you joy, peace, and love.',
  'May the spirit of Christmas fill your heart with warmth and happiness. You are a special person and Executionlab is lucky to have you with us. Have a blessed Christmas and a fantastic New Year!',
  'Hope you have a magical Christmas and a wonderful New Year! You are a joy to be around and Executionlab cherish your companion. May you always be surrounded by love and happiness.',
];