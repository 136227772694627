import { useState } from 'react';
import { useAtom } from 'jotai';
import { appStateAtom, giftMessageAtom } from 'atom';
import { randomMessage, textAnimate } from 'helper';
import {
	ERROR_COMMON,
	ERROR_NAME_EMPTY,
	NOT_FOUND_CODE,
	SUBMIT_DONE_MSG,
} from '../../../constants';
import { motion, Variants } from 'framer-motion';
import { AppState } from 'interface';
import { addMessageToFirebase } from 'utils/firebase';
import toast from 'react-hot-toast';
import FormHeader from './header';
import NameInput from './Input';
import MsgInput from './Texarea';
import SubmitButton from './Button';
import ChristmasText from 'assets/images/2024/chirstmas_text.png';
import DividerPattern from './Divider';
import { cn } from 'lib/utils';

interface Props {
	code?: string;
}

export const itemVariant: Variants = {
	hidden: { opacity: 0, marginTop: '-20px', transition: { duration: 1 } },
	show: { opacity: 1, marginTop: '0px', transition: { duration: 1 } },
};

const FormInfo: React.FC<Props> = ({ code }) => {
	const [appState, setAppState] = useAtom(appStateAtom);
	const [giftMessage] = useAtom(giftMessageAtom);
	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
	const [msg, setMsg] = useState<string>('');

	const updateMsg = (v: string) => setMsg(v);

	const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (appState === 'valid' && code) {
			if (!name.trim()) {
				toast.error(ERROR_NAME_EMPTY);
				return;
			}
			setSubmitting(true);
			const isSuccess = await addMessageToFirebase(name.trim(), msg, code);
			if (isSuccess) {
				setAppState('exist');
				textAnimate(SUBMIT_DONE_MSG, updateMsg);
			} else {
				toast.error(ERROR_COMMON);
			}
			setSubmitting(false);
		}

		if (appState === 'open' && code) {
			setAppState('opened');
			const finalMsg = giftMessage || randomMessage();
			textAnimate(finalMsg, updateMsg);
		}
	};

	const handleDisplayMsg = (appState?: AppState) => {
		if (appState === 'invalid') {
			textAnimate(NOT_FOUND_CODE, updateMsg);
		}

		if (appState === 'exist') {
			textAnimate(SUBMIT_DONE_MSG, updateMsg);
		}
	};

	const container = {
		hidden: { opacity: 0 },
		show: {
			opacity: 1,
			transition: {
				staggerChildren: 0.8,
				duration: 1,
				stiffness: 80,
				damping: 15,
			},
		},
	};

	return (
		<form className='form-info'>
			<motion.div
				className={cn('form-wrapper')}
				variants={container}
				initial='hidden'
				animate='show'
				onAnimationComplete={() => handleDisplayMsg(appState)}
			>
				<FormHeader />

				{appState === 'open' && (
					<motion.img
						src={ChristmasText}
						alt='merry christmas'
						variants={itemVariant}
					/>
				)}

				{appState === 'opened' && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{
							opacity: 1,
							transition: {
								delay: 0.5,
								duration: 1,
							},
						}}
					>
						<DividerPattern />
					</motion.div>
				)}

				{appState === 'valid' && (
					<motion.div variants={itemVariant}>
						<NameInput value={name} setValue={setName} label='Your name' />
					</motion.div>
				)}

				{appState !== 'open' && (
					<motion.div variants={itemVariant}>
						<MsgInput
							value={msg}
							setValue={setMsg}
							label={appState === 'valid' ? 'Your message' : 'Message'}
						/>
					</motion.div>
				)}

				<motion.div variants={itemVariant} style={{ textAlign: 'center' }}>
					<SubmitButton
						isSubmitting={isSubmitting}
						handleClick={handleSubmit}
					/>
				</motion.div>
			</motion.div>
		</form>
	);
};

export default FormInfo;
