import 'style.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import HomePage from './pages/Home';
import QRCodePage from './pages/QRCode';
import AdminPage from 'pages/Admin';
import GiftDetail2024 from 'pages/GiftDetail2024';
import AppProvider from 'AppProvider';

function App() {
	const router = createBrowserRouter([
		{
			path: '/',
			element: <HomePage />,
		},
		{
			path: 'gifts/:id',
			element: <GiftDetail2024 />,
		},
		{
			path: 'qrcode',
			element: <QRCodePage />,
		},
		{
			path: 'admin',
			element: <AdminPage />,
		},
	]);

	return (
		<AppProvider>
			<RouterProvider router={router} />
		</AppProvider>
	);
}

export default App;
