import { appStateAtom } from 'atom';
import clsx from 'clsx';
import { motion, Variants } from 'framer-motion';
import { useAtom } from 'jotai';

interface Props {
	isSubmitting: boolean;
	handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonSubmit = ({ handleClick, isSubmitting }: Props) => {
	const [appState] = useAtom(appStateAtom);

	const cls = clsx({
		'!opacity-[50%]': isSubmitting,
		'pointer-events-none': appState !== 'open' && appState !== 'valid',
	});

	const mapText: { [key: string]: string } = {
		valid: 'Submit',
		exist: 'Thank you',
		open: 'Open to read Message',
		opened: 'Thank you',
		invalid: 'Thank you',
	};

	return (
		<button
			onClick={handleClick}
			className={cls}
			disabled={isSubmitting || (appState !== 'open' && appState !== 'valid')}
		>
			{appState && mapText?.[appState]}
		</button>
	);
};

export default ButtonSubmit;
