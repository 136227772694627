import Lottie from 'lottie-react';
import intro from 'lottie/lottie-intro.json';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import AdminTabs from 'components/AdminTabs';
import { UserCog } from 'lucide-react';
import { Toaster } from 'react-hot-toast';
import DialogPasscode from 'components/DialogPasscode';

const AdminPage = () => {
	const [showIntro, setShowInro] = useState(
		localStorage.getItem('intro') !== 'true'
	);
	const [showPasscode, setShowPasscode] = useState<boolean>(true);

	const handleAnimateComplete = () => {
		localStorage.setItem('intro', 'true');
		setShowInro(false);
	};

	const updateShowPasscode = (value: boolean) => {
		setShowPasscode(value);
	};

	const isShowPasscode = !showIntro && showPasscode;
	return (
		<div className='version-2024'>
			<div className='app-container admin-container'>
				<AnimatePresence>
					{showIntro && (
						<motion.div
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							style={{ height: '100vh' }}
							transition={{ duration: 1 }}
						>
							<Lottie
								animationData={intro}
								style={{ height: '100%' }}
								onComplete={handleAnimateComplete}
								loop={0}
							/>
						</motion.div>
					)}
				</AnimatePresence>

				<AnimatePresence>
					{isShowPasscode ? (
						<DialogPasscode
							isOpen={showPasscode}
							setOpen={updateShowPasscode}
						/>
					) : (
						!showIntro && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 1 }}
							>
								<h1 className='main-title'>
									<UserCog />
									ADMIN VIEWS
								</h1>
								<AdminTabs />
							</motion.div>
						)
					)}
				</AnimatePresence>
				<Toaster />
			</div>
		</div>
	);
};

export default AdminPage;
