import { Button } from 'components/ui/button';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from 'components/ui/dialog';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { checkValidPasscode } from 'utils/firebase';

interface Props {
	isOpen: boolean;
	setOpen: (value: boolean) => void;
}

const DialogPasscode = ({ isOpen, setOpen }: Props) => {
	const [isLoading, setLoading] = useState<boolean>(false);
	const [passcode, setPasscode] = useState<string>('');

	const confirmPasscode = async () => {
		setLoading(true);
		const isValid = await checkValidPasscode(passcode);
		if (isValid) {
			setOpen(false);
		} else {
			toast.error('Passcode is invalid!');
		}
		setLoading(false);
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent className='sm:max-w-md'>
				<DialogHeader>
					<DialogTitle>Enter passcode:</DialogTitle>
				</DialogHeader>
				<div className='flex items-center space-x-2'>
					<div className='grid flex-1 gap-2'>
						<input
							type='text'
							className='w-full max-w-none'
							id='link'
							value={passcode}
							onChange={e => setPasscode(e.target.value)}
						/>
					</div>
				</div>
				<DialogFooter className='sm:justify-start'>
					<DialogClose asChild>
						<Button
							type='button'
							variant='secondary'
							onClick={confirmPasscode}
							disabled={isLoading}
						>
							Continue
						</Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default DialogPasscode;
