import ExeLogo from 'assets/images/2024/logo.svg';

const FormHeader = () => {
	return (
		<div className='form-header'>
			<div className='flex gap-[10px] justify-center items-center'>
				<div className='flex flex-shrink-0'>
					<img src={ExeLogo} alt='exelab logo' />
					<p className='text-base font-semibold'>-MAS GIFT</p>
				</div>
				<div className='w-[66px] text-[32px] font-semibold text-main-red text-center'>
					23 DEC
				</div>
				<div className='text-base font-semibold'>EXCHANGE</div>
			</div>
		</div>
	);
};

export default FormHeader;
