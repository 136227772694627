import { Button } from 'components/ui/button';
import { convertDateTimeStrFormat } from 'helper';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { getOpenTime, updateEventDate } from 'utils/firebase';

const SettingTab = () => {
	const [isSubmitting, setSubmitting] = useState(false);
	const [time, setTime] = useState<string>('');
	const isChangeRef = useRef<boolean>(false);

	const changeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value) {
			isChangeRef.current = true;
			setTime(e.target.value);
		} else {
			setTime('');
		}
	};

	const handleUpdateTime = async () => {
		if (time) {
			setSubmitting(true);
			let date = new Date(time);
			const isSuccess = await updateEventDate(date.getTime());
			if (isSuccess) {
				toast.success('Update successfully!');
			} else {
				toast.error('Update failed');
			}

			setSubmitting(false);
			isChangeRef.current = false;
		}
	};

	useEffect(() => {
		(async () => {
			const time = await getOpenTime();
			if (time) setTime(convertDateTimeStrFormat(time));
		})();
	}, []);

	return (
		<div className='setting-tab px-4'>
			<div className='flex gap-2 items-center'>
				<label>Event date:</label>
				<input
					type='datetime-local'
					onChange={changeTime}
					value={time}
					className='max-w-[200px]'
				/>
				<Button
					variant='outline'
					className='bg-transparent border-gray-500 hover:bg-[#b9b9b9] h-[30px]'
					disabled={!time || isSubmitting || !isChangeRef.current}
					onClick={handleUpdateTime}
				>
					Set
				</Button>
			</div>
		</div>
	);
};

export default SettingTab;
