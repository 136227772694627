import { DEFAULT_MSG } from './constants';
import { InternetTime, UserSubmitView } from 'interface';

export const ms2s = (value: number) => {
	return Math.round(value / 1000);
};

export const convertDateTimeStrFormat = (timestamp: number): string => {
	// Convert timestamp to local date
	const localDate = new Date(timestamp);

	// Format the date and time as 'YYYY-MM-DDTHH:mm'
	const offsetDateTime = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
	const formattedDateTime = offsetDateTime.toISOString().slice(0, 16);

	return formattedDateTime
};

export const getMsWithCurrentTz = (timestamp: number): number => {
	let date = new Date(timestamp);
	date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
	return date.getTime();
};

export const getTzOffset = () => new Date().getTimezoneOffset();

export const getInternetTimestamp = async (): Promise<number | undefined> => {
	try {
		const response = await fetch(
			'https://worldtimeapi.org/api/timezone/Asia/Ho_Chi_Minh'
		);
		const timeData = (await response.json()) as InternetTime;
		return new Date(timeData.datetime).getTime();
	} catch (error) {
		console.log(error);
		return undefined;
	}
};

export const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

export const textAnimate = async (
	text: string,
	updateText: (v: string) => void
) => {
	let str = '';
	for (let i = 0; i < text.length; i++) {
		str += text[i];
		updateText(str);
		await wait(20);
	}
};

export const formatDateTime = (timestamp: number): string => {
	const date = new Date(timestamp);
	var dateString =
		('0' + date.getDate()).slice(-2) +
		'/' +
		('0' + (date.getMonth() + 1)).slice(-2) +
		'/' +
		date.getFullYear() +
		' ' +
		('0' + date.getHours()).slice(-2) +
		':' +
		('0' + date.getMinutes()).slice(-2) +
		':' +
		('0' + date.getSeconds()).slice(-2);

	return dateString;
};

export const handleLoadingAndFetchData = async <T>(
	loadingMinimum: number,
	action: () => Promise<T>,
	callback: (v: T) => void
) => {
	let state: T | undefined = undefined;
	action().then(value => {
		if (state) {
			callback(value);
		} else {
			state = value;
		}
	});

	setTimeout(() => {
		if (state) {
			callback(state);
		} else {
			state = {} as T;
		}
	}, loadingMinimum);
};

export const sortBySubmitTime = (list: UserSubmitView[]): UserSubmitView[] => {
	return list.sort((a, b) => a.submittedAt - b.submittedAt);
};

export const randomMessage = (): string => {
	const randomIndex = Math.floor(Math.random() * DEFAULT_MSG.length);
	const randomMessage = DEFAULT_MSG[randomIndex];

	return randomMessage;
};
