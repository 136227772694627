import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from 'components/ui/accordion';
import SettingTab from './SettingTab';
import ViewTab from './ViewTab';

const AdminTab = () => {
	return (
		<Accordion type='multiple' className='w-full'>
			<AccordionItem value='item-1' className='mt-5'>
				<AccordionTrigger className='font-bold p-[10px] bg-[#ffffff24] rounded-[10px] shadow-sm'>
					SETTINGS
				</AccordionTrigger>
				<AccordionContent className='mt-3'>
					<SettingTab />
				</AccordionContent>
			</AccordionItem>

			<AccordionItem value='item-2' className='mt-5'>
				<AccordionTrigger className='font-bold p-[10px] bg-[#ffffff24] rounded-[10px] shadow-sm'>
					SUBMISSION VIEW
				</AccordionTrigger>
				<AccordionContent className='mt-3'>
					<h2 className='font-bold text-center text-[16px] py-2'>
						List of members have submitted from event's form
					</h2>
					<ViewTab />
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	);
};

export default AdminTab;
