interface Props {
	label: string;
	value: string;
	setValue: (value: string) => void;
}

const Input = ({ value, setValue, label }: Props) => {
	return (
		<div className='input-wrapper'>
			<label>{label}</label>
			<input value={value} onChange={e => setValue(e.target.value)} />
		</div>
	);
};

export default Input;
