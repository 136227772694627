import { appStateAtom } from 'atom';
import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';

interface Props {
	label: string;
	value: string;
	setValue: (value: string) => void;
}

const Texarea = ({ value, setValue, label }: Props) => {
	const [appState] = useAtom(appStateAtom);
	const textRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (textRef.current) {
			textRef.current.style.height = '100%';
			textRef.current.style.maxHeight = `${Math.min(
				textRef.current.scrollHeight + 12,
				280
			)}px`;
		}
	}, [value]);

	return (
		<div className='input-wrapper'>
			<label>{label}</label>
			{appState !== 'opened' ? (
				<textarea
					maxLength={300}
					value={value}
					onChange={e => setValue(e.target.value)}
					disabled={appState !== 'valid'}
				/>
			) : (
				<div
					ref={textRef}
					dangerouslySetInnerHTML={{
						__html: value.replace(
							/Executionlab/g,
							match => `<strong key=${match}>${match}</strong>`
						),
					}}
					className='final-msg'
				></div>
			)}
		</div>
	);
};

export default Texarea;
