import { PropsWithChildren, useRef } from 'react';

const AppProvider = ({ children }: PropsWithChildren) => {
	const audioRef = useRef<HTMLAudioElement>(null);

	return (
		<div
			onClick={() => {
				audioRef.current?.play();
			}}
		>
			<audio ref={audioRef} src='/music.mp3' loop />
			{children}
		</div>
	);
};

export default AppProvider;
