import { HTMLMotionProps, Variants } from 'framer-motion';

// order of appearance: 1
export const logoParentAnimate: Variants = {
  before: {
    top: '40vh',
    scale: 2,
    left: '50%',
    translateX: '-50%',
  },
  after: {
    top: '30px',
    scale: 1,
    transition: { delay: 2, duration: 0.7, staggerChildren: 0.1 },
  },
};

export const logoChildrenAnimate: Variants = {
  before: {
    opacity: 0,
    pathLength: 0,
  },
  after: {
    opacity: 1,
    pathLength: 1,
  },
};

// order of appearance: 2
export const animateEarth: HTMLMotionProps<'div'> = {
  initial: {
    opacity: 0,
    height: '0%',
  },
  animate: {
    opacity: 1,
    height: '35%',
    transition: {
      duration: 1,
      delay: 2.5,
    },
  },
};

// order of appearance: 3
export const animatePine: HTMLMotionProps<'div'> = {
  initial: {
    opacity: 0,
    right: '200%',
  },
  animate: {
    opacity: 0.7,
    right: '-200%',
    transition: {
      ease: 'easeInOut',
      duration: 4,
      delay: 2,
    },
  },
};

// order of appearance: 4
export const animateForm: HTMLMotionProps<'form'> = {
  initial: {
    left: '-180%',
  },
  animate: {
    left: '0',
    transition: {
      type: 'spring',
      duration: 3,
      stiffness: 80,
      damping: 15,
      delay: 2.5,
    },
  },
};

export const animateFormV2: HTMLMotionProps<'form'> = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 3,
      stiffness: 80,
      damping: 15,
      delay: 2.5,
    },
  },
};

export const animateSubmit: HTMLMotionProps<'button'> = {
  initial: {
    bottom: '-100px',
    opacity: 0,
  },
  animate: {
    bottom: '20px',
    opacity: 1,
    transition: {
      duration: 1,
      delay: 2.5,
    },
  },
};

export const opacityAnimate: HTMLMotionProps<'div'> = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1
    }
  }
};