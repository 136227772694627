import { opacityAnimate } from 'constants/animate';
import { motion } from 'framer-motion';

const LoadingV2 = () => {
	return (
		<motion.figure className='loader_v2' {...opacityAnimate}>
			<div className='dot white' />
			<div className='dot' />
			<div className='dot' />
			<div className='dot' />
			<div className='dot' />
		</motion.figure>
	);
};

export default LoadingV2;
