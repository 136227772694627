import Lottie from 'lottie-react';
import christmas from 'lottie/christmas.json';

const HomePage = () => {
	return (
		<div className='w-full h-full'>
			<Lottie
				animationData={christmas}
				loop={true}
				style={{ height: '100vh' }}
			/>
		</div>
	);
};

export default HomePage;
