import { QRCode } from 'react-qrcode-logo';
import { useEffect, useState } from 'react';
import { getAllGiftsCodes } from '../utils/firebase';
import { CURRENT_YEAR } from '../constants';

const fullURL = (code: string): string => {
	return `http://christmas.exelab.asia/gifts/${code}`;
};

const QRCodePage = () => {
	const [codeList, setCodeList] = useState<string[]>([]);

	useEffect(() => {
		(async () => {
			const data = await getAllGiftsCodes();
			if (data) {
				setCodeList(data[CURRENT_YEAR]);
			}
		})();
	}, []);

	return (
		<div className='p-3 flex flex-wrap gap-5 font-poppins'>
			{codeList?.map((code, index) => (
				<div key={code}>
					<h1 className='p-3'>
						{index + 1}-{code}
					</h1>
					<div className='border-solid border-[1px] border-[#707070] w-fit'>
						<QRCode
							value={fullURL(code)}
							logoImage='/exelab.png'
							logoPadding={1}
							qrStyle='dots'
							eyeRadius={5}
							ecLevel='Q'
							size={100}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export default QRCodePage;
