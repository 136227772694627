import { getApps, initializeApp } from 'firebase/app';
import { doc, serverTimestamp, Timestamp } from '@firebase/firestore';
import {
	collection,
	getFirestore,
	setDoc,
	getDoc,
	DocumentSnapshot,
	getDocs,
	query,
	updateDoc,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import {
	GiftCodes,
	OpenDate,
	PassCode,
	UserSubmitInfo,
	UserSubmitView,
} from '../interface';
import { CURRENT_YEAR, QR_URLS } from '../constants';
import { ms2s, sortBySubmitTime } from 'helper';

const firebaseConfig = {
	apiKey: 'AIzaSyAgrsi_0a01O-wBEmmvZI_817LrxJtkpIM',
	authDomain: 'te-christmas.firebaseapp.com',
	projectId: 'te-christmas',
	storageBucket: 'te-christmas.appspot.com',
	messagingSenderId: '657179166812',
	appId: '1:657179166812:web:822e2d172cd97fb288fe99',
	measurementId: 'G-MKYWS6YFX3',
};

const app =
	getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };

export const giftsCollectionRef = collection(db, `gifts_${CURRENT_YEAR}`);
export const settingsCollectionRef = collection(db, 'settings');

export const addMessageToFirebase = async (
	name: string,
	msg: string,
	id: string
): Promise<boolean> => {
	const submitted_at = ms2s(new Date().getTime());
	try {
		await setDoc(doc(db, `gifts_${CURRENT_YEAR}`, id), { name, msg, submitted_at });
		return true;
	} catch {
		return false;
	}
};

export const findMessageFromFirebase = async (id: string) => {
	const data = (await getDoc(
		doc(giftsCollectionRef, id)
	)) as DocumentSnapshot<UserSubmitInfo>;
	return data;
};

export const getFromSettings = async <T>(
	key: string
): Promise<T | undefined> => {
	const data = (await getDoc(
		doc(settingsCollectionRef, key)
	)) as DocumentSnapshot<T>;

	return data.data();
};

export const getAllGiftsCodes = async (): Promise<GiftCodes | undefined> => {
	return getFromSettings<GiftCodes>('gift_codes');
};

export const getPassCode = async (): Promise<PassCode | undefined> => {
	return getFromSettings<PassCode>('pass_code');
};

export const getAllSubmitMessage = async (): Promise<UserSubmitView[]> => {
	const querySnapshot = await getDocs(query(collection(db, `gifts_${CURRENT_YEAR}`)));
	const msgList: UserSubmitView[] = [];
	querySnapshot.forEach(doc => {
		const data = doc.data();
		msgList.push({
			name: data.name,
			msg: data.msg,
			id: doc.id,
			submittedAt: data?.submitted_at,
		});
	});

	return sortBySubmitTime(msgList);
};

export const getOpenTime = async (): Promise<number | undefined> => {
	const data = await getFromSettings<OpenDate>('open_date');
	if (!data || !data.value) return undefined;
	return data.value;
};

export const addGiftCodesToFirebase = async () => {
	const docR = await setDoc(doc(db, 'settings', 'gift_codes'), {
		CURRENT_YEAR: QR_URLS[CURRENT_YEAR],
	}, {
		merge: true
	});
	console.log(docR);
};

export const updateEventDate = async (value: number): Promise<boolean> => {
	try {
		await updateDoc(doc(db, 'settings', 'open_date'), {
			value,
		});
		return true;
	} catch {
		return false;
	}
};

export const checkValidPasscode = async (
	passcode: string
): Promise<boolean> => {
	const data = await getFromSettings<PassCode>(passcode);
	if (!data || !data.value || data.value !== 'passcode') {
		return false;
	}
	return true;
};

export const getServerTime = async () => {
	// Use more meaningful collection and document names
	const tempDocRef = doc(db, "server_utils", "current_time");

	// Write a temporary document with serverTimestamp
	await setDoc(tempDocRef, { serverTime: serverTimestamp() });

	// Read the document to get the timestamp
	const tempDocSnap = await getDoc(tempDocRef);
	if (tempDocSnap.exists()) {
		const serverTime = (tempDocSnap.data().serverTime as Timestamp).toDate();
		return serverTime;
	} else {
		throw new Error("Failed to fetch server time.");
	}
};